import * as React from "react"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import { Button, Divider, Space, Table, Typography } from "antd"
import PageContent from "../components/page-content"
import { graphql, Link, navigate } from "gatsby"
import { JsonDebugger } from "../components/json-debugger"
import { GatsbyImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
const { Title } = Typography
// export const query = graphql`
//   {
//     allPrismicAffiliate {
//       edges {
//         node {
//           id
//           data {
//             name
//             active
//             url {
//               url
//               target
//             }
//             type {
//               document {
//                 ... on PrismicAffiliateType {
//                   id
//                   data {
//                     name
//                   }
//                 }
//               }
//             }
//             image {
//               alt
//               gatsbyImageData
//             }
//           }
//         }
//       }
//     }
//   }
// `

const AffiliateProgram = ({ data }) => {
  //   const [formContent, setFormContent] = React.useState(null)
  const [showForm, setShowForm] = React.useState(false)
  const openForm = () => {
    const hbspt =
      typeof window !== "undefined" && window.hbspt ? window.hbspt : null
    if (hbspt) {
      //   setShowForm(true)
      // https://legacydocs.hubspot.com/docs/methods/forms/advanced_form_options
      hbspt.forms.create({
        region: "na1",
        portalId: "5589153",
        formId: "e5a37ab9-62ce-43b5-adcf-e8b3c93ee647",
        target: "#hubspotForm",
        onBeforeFormInit: () => setShowForm(true),
      })
    }
  }

  //   React.useEffect(() => {
  //     console.log("hbspt", hbspt)
  //     console.log({ loaded })
  //     if (!loaded && hbspt) {
  //       setTimeout(() => {
  //         hbspt.forms.create({
  //           region: "na1",
  //           portalId: "5589153",
  //           formId: "e5a37ab9-62ce-43b5-adcf-e8b3c93ee647",
  //           target: "#hubspotForm",
  //         })
  //         setLoaded(true)
  //       }, 100)
  //     }
  //   }, [hbspt, loaded])
  return (
    <Layout>
      <Seo title={"Affiliate Program"} />
      <Helmet>
        <script
          charSet="utf-8"
          type="text/javascript"
          src="//js.hsforms.net/forms/shell.js"
        ></script>
      </Helmet>
      <PageContent>
        <Title>Affiliate Program</Title>
        <div style={{ position: "relative", paddingBottom: "59.5%" }}>
          <iframe
            src="https://docs.google.com/presentation/d/e/2PACX-1vRAjqRCFaC3ez4JwKatzW_dxZpNwWaLZp_b9PHuK881w2ShmkR-f5EI_vzlluF7cxHZwXAGu5bUyHMn/embed?start=true&loop=true&delayms=3000"
            frameBorder="0"
            width="960"
            height="569"
            allowFullScreen
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              left: "0px",
              top: "0px",
            }}
          ></iframe>
        </div>
        <br />
        <br />
        {/* <h2 className="ant-typography">Get Started Today</h2> */}
        {!showForm && (
          <Button
            onClick={openForm}
            type="primary"
            shape="round"
            size="large"
            block
          >
            Get Started Today
          </Button>
        )}
        <div id="hubspotForm" />
        <br />
      </PageContent>
    </Layout>
  )
}

export default AffiliateProgram
